import Vue from 'vue';
import AddStepModal from '../AddStepModal.vue';
import EditTransitionsModal from '../EditTransitionsModal.vue';
import EditFormsModal from '../EditFormsModal.vue';
import EditStageModal from '../EditStageModal';
import NotificationModal from '../NotificationModal';
import httpClient from '@/utils/httpClient';
import EditInstructionsModal from '../EditInstructionsModal.vue';

const fillData = (component, parent, props) => {
  return new Promise(resolve => {
    var ComponentClass = Vue.extend(component);
    var instance = new ComponentClass({
      propsData: props,
      parent
    });
    instance.$mount();
    document.body.appendChild(instance.$el);

    const closeWithResult = result => {
      document.body.removeChild(instance.$el);
      instance.$destroy();
      resolve(result);
    };
    instance.$on('confirm', props => closeWithResult(props));
    instance.$on('cancel', () => closeWithResult());
  });
};

const applyTasksOptions = async (props, workflow, interaction) => {
  try {
    const stepIdTasksCount = +(await httpClient.get(
      `/api/workflows/v2/workflows/${workflow.id}/milestones/${workflow.milestoneId}/steps/${workflow.stepId}/tasks/count`
    ));

    if (stepIdTasksCount === 0) {
      return props;
    }

    const confirmResult = await interaction.dialog({
      title: interaction.title,
      message: `There are ${stepIdTasksCount} tasks created from this template.\n\n${interaction.action}`,
      confirm: 'Yes',
      cancel: 'No'
    });

    if (confirmResult) {
      const updatedTasksCount = await httpClient.post(
        `/api/workflows/v2/workflows/${workflow.id}/milestones/${workflow.milestoneId}/steps/${workflow.stepId}/options`,
        props
      );
      interaction.notify?.success({
        title: 'Task(s) updated',
        message: `${updatedTasksCount} task(s) successfully updated`
      });
    } else {
      return false;
    }
  } catch (error) {
    interaction.notify?.error({
      title: 'Task(s) update error',
      message: `Failed to updated task(s). Please try again or connect your administrator`
    });
    return false;
  }

  return props;
};

export default (editor, parent) => {
  // editor.on('translate', ({ transform, x, y }) => {
  //   debugger;
  // })
  editor.bind('connectionCreatedSetup');
  editor.on('connectionCreatedSetup', connections => {
    fillData(AddStepModal, parent, {
      connections,
      action: 'update'
    }).then(newData => {
      if (newData) {
        connections.forEach((c, index) => {
          const target = newData.find(item => item.index === index);
          if (target) {
            c.data.to = target;
          } else {
            editor.removeConnection(c);
          }
        });
      }
    });
  });

  editor.bind('createNowhereConnection');
  editor.on('createNowhereConnection', output => {
    const nowhereInput = editor.nodes.find(n => n.name === 'nowhere').inputs.get('nowhere____input');
    editor.connect(output, nowhereInput);
    fillData(EditFormsModal, parent, { connections: output.connections }).then(data => {
      if (data && data.form && data.form.properties.length) {
        output.connections.forEach(c => {
          if (data.form && data.form.properties.length) {
            c.data.from = data.form;
          } else {
            c.data.from = undefined;
          }
        });
        data.deletedConnections.forEach(index => {
          editor.removeConnection(connections[index]);
        });
        parent.hasChanges = true;
      } else {
        editor.removeConnection(output.connections[0]);
      }
    });
  });

  editor.on('connectioncreate', connection => {
    const inputId = connection.input.node.id;

    const hasConnection = connection.output.connections.find(c => c.input.node.id === inputId);
    if (hasConnection) {
      return false;
    }
  });

  editor.on('connectioncreated', connection => {
    if (connection.input.name.toLowerCase() === 'finish' || connection.input.name.toLowerCase() === 'nowhere') {
      return;
    }
    if (connection.output.connections[0] && connection.output.connections[0].data.from) {
      connection.data.from = connection.output.connections[0].data.from;
    }

    fillData(AddStepModal, parent, {
      connections: [connection]
    }).then(async newData => {
      if (!newData) {
        editor.removeConnection(connection);
      } else {
        connection.data.to = newData[0];
      }
    });
  });

  editor.bind('transitionsSetup');
  editor.on('transitionsSetup', props => {
    fillData(EditTransitionsModal, parent, { stepProperties: props }).then(data => {
      if (data) {
        props.transitions = data;
        parent.hasChanges = true;
      }
    });
  });

  editor.bind('formsSetup');
  editor.on('formsSetup', connections => {
    fillData(EditFormsModal, parent, { connections }).then(data => {
      if (data) {
        connections.forEach(c => {
          if (data.form && data.form.properties.length) {
            c.data.from = data.form;
          } else {
            c.data.from = undefined;
            if (c.input.name === 'nowhere') {
              editor.removeConnection(c);
            }
          }
        });
        data.deletedConnections.forEach(index => {
          editor.removeConnection(connections[index]);
        });
        parent.hasChanges = true;
      }
    });
  });

  editor.bind('stepDurationChanged');

  editor.on('stepDurationChanged', ({ node, duration }) => {
    node.data.props.durationHours = duration;
    parent.hasChanges = true;
  });

  editor.bind('editRoadMapStage');
  editor.on('editRoadMapStage', ({ id, props }) => {
    if (!parent.milestone.roadmap?.length) {
      return;
    }

    const allStages = parent.milestone.roadmap.map(r => r.name);

    const currentOption = parent.milestone.roadmap.find(s => s.steps.includes(id));
    fillData(EditStageModal, parent, {
      currentOption: currentOption ? currentOption.name : null,
      options: allStages,
      title: props.title
    }).then(data => {
      if (props.isAddedToRoadMap && !data) {
        parent.hasChanges = true;
      }

      const currentOption = parent.milestone.roadmap.find(s => s.name === data);
      if (!currentOption) {
        return;
      }

      parent.milestone.roadmap.forEach(stage => {
        const index = stage.steps.findIndex(s => s === id);

        if (index > -1) {
          stage.steps.splice(index, 1);
        }
      });
      currentOption.steps.push(id);
      props.isAddedToRoadMap = true;
      parent.hasChanges = true;
    });
  });

  editor.bind('showNotifications');
  editor.on('showNotifications', async ({ id, props }) => {
    const data = await fillData(NotificationModal, parent, { stepProperties: props });

    if (!data) {
      return;
    }

    const result = await applyTasksOptions(
      {
        options: {
          notification: data
        }
      },
      {
        id: parent.milestone.workflowId,
        milestoneId: parent.milestone.id,
        stepId: id
      },
      {
        title: 'Tasks email notification',
        action: `Do you want to set email notification for them to '${data.email ? data.email : 'none'}'?`,
        dialog: parent.$confirm,
        notify: parent.$toast
      }
    );
    if (result) {
      props.options.notifications = data;
      parent.hasChanges = true;
    }
  });

  editor.bind('criticalDateStateChanged');
  editor.on('criticalDateStateChanged', async ({ node }) => {
    const isCriticalDate = !node.data.props.isCriticalDate;
    const result = await applyTasksOptions(
      {
        isCriticalDate: isCriticalDate
      },
      {
        id: parent.milestone.workflowId,
        milestoneId: parent.milestone.id,
        stepId: node.id
      },
      {
        title: 'Critical tasks',
        action: `Do you want to ${isCriticalDate ? 'mark' : 'unmark'} them as critical date tasks?`,
        dialog: parent.$confirm,
        notify: parent.$toast
      }
    );

    if (result) {
      node.data.props.isCriticalDate = isCriticalDate;
      parent.hasChanges = true;
    }
  });

  editor.bind('setInstructions');
  editor.on('setInstructions', async ({ node }) => {
    const interaction = node.data.props.options.instructions || '';
    const data = await fillData(EditInstructionsModal, parent, { value: interaction });

    if (!data) {
      return;
    }

    node.data.props.options.instructions = data.instructions === '<p></p>' ? '' : data.instructions;

    if (!parent.hasChanges) {
      parent.hasChanges = interaction !== data.instructions;
    }
  });

  editor.on('nodecreated noderemoved connectioncreated connectionremoved', () => {
    parent.hasChanges = true;
  });

  editor.on('updateconnection', () => {
    parent.hasChanges = true;
  });
};
