<template>
  <div class="due-at-wrapper">
    <p-multiselect
      :value="value.date"
      :disabled="disabled"
      label="Source"
      placeholder="Variable"
      :options="availableContracts"
      @input="e => onChange('date', e)"
    />
    <p-text-field
      :value="value.amount"
      :disabled="disabled"
      label="Amount"
      placeholder="Number of {measures} to add(substract)"
      type="number"
      @input="e => onChange('amount', e)"
    />
    <p-multiselect
      :value="value.measures"
      :disabled="disabled"
      placeholder=""
      :options="measuresOptions"
      label="Measures"
      @input="e => onChange('measures', e)"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

import TextField from '@/components/common/TextField.vue';
import Multiselect from '@/components/common/Multiselect';

import { mapVariablesFromContracts } from './utils';

const measuresOptions = ['Days', 'Business Days', 'Weeks', 'Months', 'Years'];
export default {
  components: {
    'p-text-field': TextField,
    'p-multiselect': Multiselect
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        date: '',
        amount: 1,
        measures: 'Days'
      })
    },
    code: {
      type: String,
      default: ''
    },
    source: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean
    },
    additionalOptions: {
      type: Array,
      default: () => []
    },
    excludeContracts: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      measuresOptions
    };
  },
  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts
    }),
    availableContracts: function() {
      const options = mapVariablesFromContracts(this.contracts, this.source, this.code, 'date', undefined, this.excludeContracts);
      return ['{{now}}', ...options, ...this.additionalOptions];
    }
  },
  methods: {
    onChange(field, value) {
      this.$emit('input', {
        ...this.value,
        [field]: value
      });
    }
  }
};
</script>
<style lang="scss" scoped>
.due-at-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 5px;
}
</style>
