<template>
  <form :class="{ dirty: $v.$anyDirty }" autocomplete="off" class="create-or-edit-form" @submit.prevent="submit">
    <div class="form-row">
      <p-multiselect
        v-model="type"
        :disabled="!$hasPermission('forms')"
        :options="['docx', 'xfa', 'xfah', 'xfdf']"
        :multiple="false"
        :taggable="false"
        label="Type"
        placeholder=""
        :searchable="false"
        :get-label-callback="getLabelCallback"
      ></p-multiselect>
      <ul v-if="$v.type">
        <li v-if="!$v.type.required" class="error">Type is required</li>
      </ul>
    </div>
    <div class="form-row">
      <p-file
        :label="'Form file'"
        :value="form"
        :accept="formExt"
        :disabled="!formExt || !$hasPermission('forms')"
        :placeholder="item && item.type === type ? '(existing)' : ''"
        @input="formSelected"
      ></p-file>
      <ul v-if="$v.form">
        <li v-if="!$v.form.required" class="error">Form file is required</li>
      </ul>
    </div>
    <div v-if="isModelRequired" class="form-row">
      <p-file
        :key="type"
        :label="'Model file'"
        :value="model"
        :accept="modelExt"
        :disabled="!modelExt || !$hasPermission('forms')"
        :placeholder="item && item.type === type ? '(existing)' : ''"
        @input="modelSelected"
      ></p-file>
      <ul v-if="$v.model">
        <li v-if="!$v.model.required" class="error">Model file is required</li>
      </ul>
    </div>
    <div class="form-row">
      <p-text-field v-model="name" :label="'Name'" autocomplete="off" :disabled="true" />
      <ul v-if="$v.name">
        <li v-if="!$v.name.required" class="error">Name is required</li>
      </ul>
    </div>
    <div class="form-row">
      <p-text-field v-model="description" :disabled="!$hasPermission('forms')" :label="'Description'" autocomplete="off" />
    </div>
    <div class="form-row">
      <p-text-field v-model="fileNameTemplate" :disabled="!$hasPermission('forms')" :label="'Filename Template'" autocomplete="off" />
      <ul v-if="$v.fileNameTemplate">
        <li v-if="!$v.fileNameTemplate.required" class="error">Filename Template is required</li>
      </ul>
    </div>
    <div class="form-row">
      <p-text-field v-model="foreach" :label="'Foreach'" autocomplete="off" />
    </div>
    <div class="form-row">
      <p-multiselect
        v-model="clients"
        :multiple="true"
        :taggable="true"
        label="Clients"
        :disabled="!$hasPermission('forms')"
        placeholder=""
        tag-placeholder=""
        @tag="clientAdded"
      ></p-multiselect>
    </div>
    <div class="form-row">
      <p-multiselect
        v-model="tags"
        :multiple="true"
        :disabled="!$hasPermission('forms')"
        :taggable="true"
        label="Tags"
        placeholder=""
        tag-placeholder=""
        @tag="tagAdded"
      ></p-multiselect>
    </div>
    <div v-if="isRequestFailed" class="error">
      Failed to save a form. Check your input and try again.
    </div>
    <div class="form-row submit">
      <p-button type="button" @click.prevent="$router.back()">Cancel</p-button>
      <p-button v-if="$hasPermission('forms')" color="primary" type="submit" :disabled="isRequestPending || ($v.$anyDirty && $v.$invalid)"
        >Save</p-button
      >
    </div>
  </form>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import TextField from '@/components/common/TextField';
import Button from '@/components/common/Button';
import File from '@/components/common/File';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-text-field': TextField,
    'p-button': Button,
    'p-file': File,
    'p-multiselect': Multiselect
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    isRequestPending: {
      type: Boolean,
      default: false
    },
    isRequestFailed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    if (this.item) {
      return {
        form: null,
        model: null,
        name: this.item.name,
        description: this.item.description,
        type: this.item.type,
        fileNameTemplate: this.item.fileNameTemplate,
        foreach: this.item.foreach,
        clients: this.item.clients,
        tags: this.item.tags
      };
    }
    return {
      form: null,
      model: null,
      name: '',
      description: '',
      type: '',
      fileNameTemplate: '',
      foreach: '',
      clients: [],
      tags: []
    };
  },
  computed: {
    formExt() {
      if (this.type === 'xfa' || this.type === 'xfah' || this.type === 'xfdf') {
        return '.pdf';
      }
      if (this.type === 'docx') {
        return '.docx';
      }

      return null;
    },
    modelExt() {
      if (this.type === 'xfa' || this.type === 'xfah' || this.type === 'xfdf') {
        return `.${this.type},.xml`;
      }

      return null;
    },
    isModelRequired() {
      return this.type === 'xfa' || this.type === 'xfah' || this.type === 'xfdf';
    }
  },
  validations() {
    return {
      ...(this.item === null ? { type: { required } } : {}),
      ...(this.item === null || this.item.type !== this.type ? { form: { required } } : {}),
      ...((this.item === null || this.item.type !== this.type) && this.type !== 'docx' ? { model: { required } } : {}),
      ...(this.item === null ? { name: { required } } : {}),
      ...(this.item === null ? { fileNameTemplate: { required } } : {})
    };
  },
  methods: {
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit('submit', {
        form: this.form,
        model: this.model,
        name: this.name && this.name.trim(),
        description: this.description && this.description.trim(),
        type: this.type,
        fileNameTemplate: this.fileNameTemplate,
        foreach: this.foreach,
        clients: this.clients,
        tags: this.tags
      });

      this.$v.$reset();
    },
    tagAdded(tag) {
      if (!Array.isArray(this.tags)) {
        this.tags = [];
      }

      if (this.tags.includes(tag)) {
        return;
      }
      this.tags.push(tag);
    },
    clientAdded(client) {
      if (!Array.isArray(this.clients)) {
        this.clients = [];
      }

      if (this.clients.includes(client)) {
        return;
      }
      this.clients.push(client);
    },
    formSelected(form) {
      const [name] = form.name.split('.');
      this.name = name;
      this.form = form;
    },
    modelSelected(model) {
      this.model = model;
    },
    getLabelCallback(value) {
      switch (value) {
        case 'docx':
          return 'Microsoft Word document template (.docx)';
        case 'xfa':
          return 'PDF using XML Forms Architecture (.xfa)';
        case 'xfah':
          return 'PDF using XML Forms Architecture (.xfah)';
        case 'xfdf':
          return 'PDF using XML Forms Data Format (.xfdf)';
        default:
          return value;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.create-or-edit-form {
  padding: 2rem;
  max-width: 480px;
  background: var(--theme-surface);
  overflow-y: auto;

  > * {
    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
    &:last-child {
      display: flex;
      justify-content: flex-end;
    }
  }

  .error {
    font-size: 0.8rem;
    color: var(--theme-error);
    text-align: left;
    padding: 0.25rem 0;
    display: none;
  }
  &.dirty {
    .error {
      display: block;
    }
  }
}
</style>
