<template>
  <p-modal :visible="true">
    <span slot="title"> {{ action }} connection </span>
    <section class="add-step-modal">
      <div>
        <div v-if="localConnections.length > 1" class="tabs">
          <template v-for="(tab, index) in localConnections">
            <div :key="index" :class="{ active: activeTab === index }" class="tab" @click="activeTab = index">
              <span>
                {{ `${connections[tab.index].output.node.data.props.title}(${connections[tab.index].output.name})` }}
              </span>
              <p-button variant="text" style="font-size: 2rem" @click.stop="deleteConnection(index)"><md-icon name="close"/></p-button>
            </div>
          </template>
        </div>
        <div class="row">
          <predicates
            :key="activeTab"
            v-model="localConnections[activeTab].predicates"
            :code="localConnections[activeTab].eventSource.code"
            :source="localConnections[activeTab].eventSource.source"
          />
        </div>
        <div class="row">
          <h2 class="title">Title:</h2>
          <p-input-variables
            :key="activeTab"
            v-model="localConnections[activeTab].title"
            :additional-options="simpleVariables"
            :source="source"
            :code="code"
            :placeholder="'Title of task created by this step'"
          />
        </div>
        <div class="row">
          <h2 class="title">Due At:</h2>
          <p-wizard-due-at
            :key="activeTab"
            v-model="localConnections[activeTab].dueAt"
            :source="source"
            :code="code"
            :additional-options="dateVariables"
          />
        </div>
        <div class="row">
          <h2 class="title">Assignees:</h2>
          <p-assignees-with-variables
            :key="activeTab"
            v-model="localConnections[activeTab].assignees"
            :additional-options="peopleVariables"
            :source="source"
            :code="code"
          />
        </div>
        <div class="row">
          <h2 class="title">Attachments:</h2>
          <p-input-variables
            :key="activeTab"
            v-model="localConnections[activeTab].attachments"
            :additional-options="attachmentsVariables"
            :source="source"
            :code="code"
            :data-type="'file'"
            :exclude-contracts="['uspto', 'invention', 'milestone']"
            :placeholder="'Files attached to this task'"
          />
        </div>
        <div class="row">
          <h2 class="title">Notes:</h2>
          <p-input-variables
            :key="activeTab"
            v-model="localConnections[activeTab].notes"
            :source="source"
            :multiline="true"
            :additional-options="simpleVariables"
            :code="code"
          />
        </div>
      </div>
    </section>
    <div slot="footer">
      <p-button @click="$emit('cancel')">Cancel</p-button>
      <p-button color="secondary" @click="onConfirm">{{ action }} connection</p-button>
    </div>
  </p-modal>
</template>

<script>
import Button from '@/components/common/Button';
import InputWithVariables from './parts/InputWithVariables.vue';
import AssigneesWithVariables from './parts/AssigneesWithVariables.vue';
import DueDateWithVariables from './parts/DueDateWithVariables.vue';
import { mapState } from 'vuex';
import Modal from '@/components/common/Modal';
import Predicates from '../workflow/wizard-parts/Predicates.vue';
import MdIcon from '@/components/common/MdIcon';

export default {
  components: {
    'p-button': Button,
    'p-wizard-due-at': DueDateWithVariables,
    'p-assignees-with-variables': AssigneesWithVariables,
    'p-input-variables': InputWithVariables,
    'p-modal': Modal,
    Predicates,
    MdIcon
  },
  props: {
    connections: {
      type: Array,
      default: () => []
    },
    value: {
      type: Object,
      default: () => ({})
    },
    action: {
      type: String,
      validator: function(value) {
        return ['create', 'update'].indexOf(value) !== -1;
      },
      default: 'create'
    }
  },

  data() {
    return {
      localConnections: this.connections.map((connection, index) => ({
        index,
        title: connection.data.to ? connection.data.to.title : '',
        notes: connection.data.to ? connection.data.to.notes : '',
        eventSource: connection.output.node.data.props.isEventSource
          ? {
              source: ['milestone-start', 'workflow-form', connection.output.node.data.props.source],
              code: ['workflow.milestone.created', 'workflow.next', connection.output.name]
            }
          : {
              source: ['workflow', 'workflow-form'],
              code: ['workflow.next', 'workflow.next']
            },
        form: connection.data.from,
        predicates: (connection.data.to && connection.data.to.predicates) || [],
        dueAt: {
          date: connection.data.to ? connection.data.to.dueAt.date : '',
          amount: connection.data.to ? connection.data.to.dueAt.amount : 0,
          measures: connection.data.to ? connection.data.to.dueAt.measures : 'Days'
        },
        assignees: connection.data.to ? connection.data.to.assignees : [],
        attachments: connection.data.to ? connection.data.to.attachments : []
      })),
      activeTab: 0
    };
  },

  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts,
      milestone: s => s.milestones.item
    }),
    source() {
      return this.connections[this.activeTab].output.node.data.props.source;
    },
    code() {
      return this.connections[this.activeTab].output.name;
    },
    currentFormFields() {
      if (this.localConnections[this.activeTab].form) {
        return this.localConnections[this.activeTab].form.properties;
      }
      return [];
    },
    peopleVariables() {
      const people = this.milestone.variables ? this.milestone.variables.filter(v => v.type === 'people') : [];
      const formPeople = this.currentFormFields.filter(v => v.type === 'people').map(v => `{{form.${v.property}}}`);
      return ['{{thisTask.assignees}}', '{{thisTask.assignees.assistants}}', ...people.map(d => d.saveTo), ...formPeople];
    },
    attachmentsVariables() {
      const files = this.milestone.variables ? this.milestone.variables.filter(v => v.type === 'file') : [];
      const formFiles = this.currentFormFields.filter(v => v.type === 'file').map(v => `{{form.${v.property}}}`);
      const resp = {
        thisTask: ['{{thisTask.attachments}}']
      };
      if (files?.length) {
        resp['milestone.variables'] = files.map(d => d.saveTo);
      }
      if (formFiles?.length) {
        resp['form'] = formFiles;
      }
      return resp;
    },
    simpleVariables() {
      const resp = {
        thisTask: ['{{thisTask.notes}}', '{{thisTask.title}}', '{{thisTask.dueAt}}', '{{thisTask.assignees}}']
      };
      const vars = this.milestone.variables ? this.milestone.variables : [];
      if (vars) {
        resp['milestone.variables'] = vars.map(d => d.saveTo);
      }
      if (this.currentFormFields.length) {
        resp['form'] = this.currentFormFields.map(v => `{{form.${v.property}}}`);
      }
      return resp;
    },
    dateVariables() {
      const dates = this.milestone.variables ? this.milestone.variables.filter(v => v.type === 'date') : [];

      const formDates = this.currentFormFields.filter(v => v.type === 'date' || v.type === 'datetime').map(v => `{{form.${v.property}}}`);
      return ['{{thisTask.dueAt}}', ...dates.map(d => d.saveTo), ...formDates];
    }
  },
  methods: {
    onConfirm() {
      this.$emit(
        'confirm',
        this.localConnections.map(({ form, ...other }) => ({ ...other }))
      );
    },
    deleteConnection(index) {
      this.localConnections.splice(index, 1);
      this.activeTab = 0;
    }
  }
};
</script>

<style lang="scss" scoped>
.add-step-modal {
  padding: 20px;
  header {
    h2 {
      font-size: 1.2rem;
      font-weight: 500;
      text-transform: uppercase;
      padding: 1.75rem 2rem 0.75rem 2rem;
    }
  }
  .row {
    margin-bottom: 20px;
    border: 1px solid #fff;
    padding: 20px;
    .title {
      margin-bottom: 10px;
    }
  }
  .tabs {
    display: flex;
    width: 100%;
    margin-bottom: 20px;
    .tab {
      padding: 10px;
      flex: 1;
      border: 1px solid #fff;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &.active {
        border-bottom-width: 0;
        cursor: default;
      }
    }
  }
}
</style>
