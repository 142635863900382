<template>
  <section v-if="isGetCollectionRequestPending">
    <p-loading />
  </section>
  <section v-else class="teams">
    <div class="team-list-wrapper">
      <div class="header-wrapper">
        <div class="select-wrapper">
          <p-multiselect v-model="filter" :options="['all', 'assistants', 'teams']" @input="onFilterChange" />
        </div>
        <div>
          <router-link v-if="$hasPermission('users.write')" to="teams/new">+&nbsp;add new</router-link>

          <label>Total:</label>&nbsp;{{ total }}
        </div>
      </div>
      <ul class="team-list">
        <li class="team-list-item header">
          <!-- <div>Id</div> -->
          <div>Name</div>
          <div>Members</div>
          <div>Created At</div>
          <div>Updated At</div>
          <div class="action-link-wrapper"></div>
        </li>
      </ul>
      <ul class="team-list">
        <li v-for="entry in collection" :key="entry.id" class="team-list-item">
          <!-- <div>{{ entry.id }}</div> -->
          <div>{{ entry.name }}</div>
          <div>
            <ul>
              <li v-for="item of entry.members.slice(0, 3)" :key="entry.id + item">
                <label>{{ item }}</label>
              </li>
              <li v-if="entry.members.length > 3">
                <br />
                <i class="more-info">{{ entry.members.length - 3 }} more...</i>
              </li>
            </ul>
          </div>
          <div>{{ entry.createdAt && new Date(entry.createdAt).toLocaleString() }}</div>
          <div>{{ entry.updatedAt && new Date(entry.updatedAt).toLocaleString() }}</div>
          <div class="action-link-wrapper">
            <router-link v-if="$hasPermission('users.write')" :to="`teams/${entry.id}`">&#9998;</router-link>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Loading from '@/components/common/Loading';
import Multiselect from '@/components/common/Multiselect';

export default {
  components: {
    'p-loading': Loading,
    'p-multiselect': Multiselect
  },
  data() {
    return {
      filter: 'teams'
    };
  },
  computed: {
    ...mapState({
      total: s => s.teams.collection.length,
      collection: s => s.teams.collection,
      isGetCollectionRequestPending: s => s.teams.isGetCollectionRequestPending
    })
  },
  watch: {
    $route: {
      handler() {
        this.$store.dispatch('teams/getCollection', { assistants: false });
      },
      immediate: true
    }
  },
  methods: {
    onFilterChange(e) {
      const payload = {};
      if (e !== 'all') {
        payload.assistants = e === 'assistants';
      }
      this.$store.dispatch('teams/getCollection', payload);
    }
  }
};
</script>

<style lang="scss" scoped>
.teams {
  display: grid;
  padding: 1rem 1rem 0 1rem;
  // width: 100%;

  .team-list-wrapper {
    display: grid;
    grid-template-rows: max-content max-content minmax(0, 1fr);

    .header-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 0.75rem;
      margin: 0;
      padding: 0;
      font-weight: 400;
      margin-right: 6px;
      .select-wrapper {
        width: 25%;
      }
      label {
        font-weight: 500;
        margin-left: 10px;
      }

      > * {
        &:not(:last-child) {
          margin-right: 0.5rem;
        }
      }
    }
  }

  .team-list {
    margin: 0;
    padding: 0;
    list-style: none;

    .team-list-item {
      display: grid;
      grid-template-columns: 250px minmax(0, 1fr) 150px 150px 30px;
      grid-gap: 0;
      border-bottom: 1px solid var(--theme-highlight);
      font-size: 0.75rem;

      > * {
        padding: 0.5rem 0.5rem;
        border-left: 1px solid var(--theme-highlight);

        &:last-child {
          border-right: 1px solid var(--theme-highlight);
        }
      }

      &:first-child {
        border-top: 1px solid var(--theme-highlight);
      }

      &.header {
        font-size: 0.75rem;
        color: var(--theme-on-background-accent);
        > * {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          padding: 0.25rem 0.5rem;
        }
      }

      .action-link-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        border-left-color: transparent;

        > * {
          opacity: 0;
        }
      }

      &:hover {
        .action-link-wrapper {
          > * {
            opacity: 1;
          }
        }
      }

      .more-info {
        font-style: italic;
        color: var(--theme-on-background-accent);
      }
    }

    &:not(:last-child) {
      margin-right: 6px;
    }

    &:last-child {
      overflow-y: scroll;
    }
  }
}
</style>
