<template>
  <tags
    v-if="taggable && multiple && !multiselect"
    :value="value"
    :label="label"
    :placeholder="placeholder"
    :options="searchChangeCallback ? optionsAsync : options"
    :disabled="disabled"
    @search-change="find"
    @input="$emit('input', $event)"
    @change="$emit('change', $event)"
  />
  <div v-else class="multiselect-wrapper">
    <label v-if="!!label" :for="id">{{ label }} </label>
    <multiselect
      :id="id"
      :value="value"
      :loading="loading"
      :options="searchChangeCallback ? optionsAsync : options"
      :multiple="multiple"
      :searchable="searchable"
      :taggable="taggable"
      :options-limit="optionsLimit"
      :placeholder="placeholder"
      :show-no-results="false"
      :hide-selected="true"
      :internal-search="searchChangeCallback ? false : true"
      :show-labels="false"
      :show-no-options="false"
      :tag-placeholder="tagPlaceholder"
      :custom-label="getLabelCallback"
      :disabled="disabled"
      @search-change="find"
      @input="$emit('input', $event)"
      @change="$emit('change', $event)"
      @tag="$emit('tag', $event)"
    />
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Tags from './Tags';

export default {
  components: { Multiselect, Tags },
  props: {
    id: {
      type: String,
      default: () => (+new Date()).toString()
    },
    value: {
      type: [String, Array, Object],
      default: () => null
    },
    options: {
      type: Array,
      default: () => []
    },
    optionsLimit: {
      type: Number,
      default: 50
    },
    multiple: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: true
    },
    taggable: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search or add a tag'
    },
    tagPlaceholder: {
      type: String,
      default: 'Press Enter to create a tag'
    },
    searchChangeCallback: {
      type: Function,
      default: undefined
    },
    getLabelCallback: {
      type: Function,
      default: s => s
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiselect: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: false,
      optionsAsync: [...this.options]
    };
  },
  methods: {
    async find(query) {
      if (!this.searchChangeCallback) {
        return;
      }
      this.loading = true;
      this.searchChangeCallback(query).then(response => {
        this.optionsAsync = response;
        this.loading = false;
      });
    }
  }
};
</script>

<style lang="scss">
.multiselect-wrapper {
  display: grid;
  grid-template-rows: max-content max-content;
  grid-gap: 5px;
  > label {
    font-weight: 500;
    font-size: 0.75rem;
    letter-spacing: 0.025em;
  }

  .multiselect {
    box-sizing: border-box;

    font-weight: 400;
    border-radius: 2px;
    border: 1px solid var(--theme-on-background-dark);
    // font-size: 0.85rem;
    // font-weight: 400;
    color: var(--theme-on-background-dark);
    background: var(--theme-background);
    cursor: default;
    border-collapse: separate;
    min-height: 32px;
    position: relative;
    outline: none;

    &.multiselect--active {
      border-color: var(--theme-primary);
      .multiselect__content-wrapper {
        border-color: var(--theme-primary);
      }
      .multiselect__input {
        width: 100% !important;
        visibility: visible !important;
      }
    }
    .multiselect__select {
      position: absolute;
      width: 32px;
      height: 32px;
      right: 0px;
      top: 0px;
      padding: 0;
      text-align: center;
      transition: transform 0.2s ease;

      &:before {
        position: relative;
        right: 0;
        top: 65%;
        color: rgb(153, 153, 153);
        margin-top: 4px;
        border-style: solid;
        border-width: 5px 5px 0;
        border-color: rgb(153, 153, 153) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0);
        content: '';
      }
    }
    .multiselect__tags {
      min-height: 32px;
      border: 0;

      font-size: 12px;
      font-style: normal;
      border-radius: 2px;
      display: block;
      box-sizing: border-box;

      .multiselect__placeholder {
        margin: 0;

        display: block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 8px 12px;
        font-size: 0.85rem;
        font-weight: 400;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        // border: 1px solid var(--theme-on-background-dark);
        line-height: normal;
      }
      .multiselect__tags-wrap {
        display: block;
        min-height: 32px;
        overflow: hidden;
        white-space: pre-line;
        padding: 5px 32px 0px 5px;
        box-sizing: border-box;

        .multiselect__tag {
          padding: 3px 12px 2px 4px;
          background-color: var(--theme-primary);
          border: 1px solid var(--theme-primary);
          color: var(--theme-on-primary);

          margin: 0 5px 5px 0;
          border-radius: 2px;
          display: inline-flex;

          position: relative;

          > span {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: block;
          }
          .multiselect__tag-icon {
            position: absolute;
            right: 2px;
            top: 50%;
            transform: translateY(-50%);
            line-height: 1;
            // border-right: 1px solid #ccc;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 2px;
            border-top-left-radius: 2px;
            color: #999;
            &:after {
              content: '\D7';
              color: var(--theme-on-primary);
              font-size: 14px;
            }
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
      .multiselect__input {
        // margin: 0;
        // color: #aaa;
        // height: initial;
        // padding: 5px 0 2px 5px;
        // font-size: 13px;

        padding: 8px 12px;
        font-family: inherit;
        font-size: 0.85rem;
        font-weight: 400;
        border-radius: 2px;
        border: 0px none var(--theme-on-background-dark);
        color: var(--theme-on-background-dark);
        background: var(--theme-background);
        outline: none;
        transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        box-sizing: border-box;
        visibility: hidden;

        &::-webkit-input-placeholder {
          color: var(--theme-on-background-dark);
          font-size: inherit;
          font-family: inherit;
        }
      }
      .multiselect__single {
        margin: 0;

        display: block;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        padding: 8px 12px;
        font-size: 0.85rem;
        font-weight: 400;
        height: 100%;
        width: 100%;
        box-sizing: border-box;
        color: var(--theme-on-background);
        line-height: normal;
      }
    }
    .multiselect__content-wrapper {
      border-top-width: 0;
      left: -1px;
      right: -1px;
      // margin-top: -2px;
      width: auto;
      border-bottom-left-radius: 2px;
      border-bottom-right-radius: 2px;
      position: absolute;
      border: 1px solid var(--theme-on-background-dark);
      border-top-width: 0;
      color: var(--theme-on-background-dark);
      background: var(--theme-background);
      z-index: 1;
      overflow-y: auto;
      ul {
        width: 100%;
      }
      .multiselect__content {
        display: block !important;
        .multiselect__element,
        li {
          display: block;
          .multiselect__option {
            // background: transparent;
            // margin: 3px;
            // padding: 2px;
            // font-size: 13px;
            // min-height: 0;
            padding: 8px 12px;
            font-family: inherit;
            font-size: 0.85rem;
            font-weight: 400;
            white-space: initial;
            display: block;
            color: var(--theme-on-background);
            background: var(--theme-background);
            // &.multiselect__option--selected {
            //     display: none;
            // }
            &:after {
              top: 0;
              right: 0;
              position: absolute;
              padding-right: 12px;
              padding-left: 20px;
              font-family: inherit;
              font-size: 0.85rem;
              font-weight: 400;
              color: var(--theme-on-background);
              background: var(--theme-background);
              line-height: 26px;
            }

            &.multiselect__option--highlight {
              cursor: pointer;
              background: var(--theme-surface);
              &:after {
                color: var(--theme-on-surface);
                background: var(--theme-surface);
                content: attr(data-select);
              }
            }

            // &:hover {
            //   background: var(--theme-surface);
            // }
          }
        }
      }
    }
    &.multiselect--above {
      .multiselect__content-wrapper {
        border-bottom-width: 0;
        border-top-width: 1px;
        bottom: 100%;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-top-left-radius: 2px;
        border-top-right-radius: 2px;
      }
    }

    .multiselect__element > .multiselect__option > span:empty:before {
      content: '';
      display: inline-block;
    }

    .multiselect--disabled {
      background: transparent;
    }

    .multiselect--disabled .multiselect__select {
      background: transparent;
    }

    .multiselect--disabled .multiselect__tags {
      opacity: 0.7;
    }

    .multiselect--disabled input[disabled] {
      background: transparent;
    }

    .multiselect__tag > span {
      display: inline-block;
    }

    .multiselect__tag > span:empty:before {
      content: '';
      display: inline-block;
    }
  }
  //   input,
  //   textarea {
  //     padding: 8px 12px;
  //     font-size: 0.85rem;
  //     font-weight: 400;
  //     border-radius: 2px;
  //     border: 1px solid var(--theme-on-background-dark);
  //     color: var(--theme-on-background-dark);
  //     background: var(--theme-background);
  //     outline: none;
  //     transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  //     &:hover {
  //       border-color: var(--theme-on-background);
  //       color: var(--theme-on-background);
  //     }

  //     &:focus,
  //     &:active {
  //       border-color: var(--theme-on-background);
  //       color: var(--theme-on-background);
  //       outline: none;
  //     }
  //   }
}
</style>

<style lang="scss">
// .multiselect {
//   background-color: transparent;
//   color: #aaa;
//   cursor: default;
//   border-collapse: separate;
//   height: 30px;
//   min-height: 30px;
//   position: relative;
//   .clear-all {
//     width: 17px;
//     text-align: center;
//     border: none;
//     color: #999;
//     position: absolute;
//     background: transparent;
//     right: 20px;
//     height: 100%;
//     top: 0;
//     outline: none;
//     cursor: pointer;
//     font-size: 18px;
//     &:hover {
//       color: #000;
//     }
//   }
// }
</style>
