<template>
  <i :class="[color, name, size]"> </i>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: null,
      validator(value) {
        return value && value.length;
      }
    },
    color: {
      type: String,
      default: '',
      validator(value) {
        return ['', 'success', 'error'].includes(value);
      }
    },
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return ['sm', 'md', 'lg'].includes(value);
      }
    }
  }
};
</script>

<style lang="scss" scoped="true">
i {
  display: inline-flex;
  width: 20px;
  height: 20px;
  margin: 0;
  padding: 0;
  background-color: var(--theme-on-background);
  background-repeat: no-repeat;
  -webkit-mask-position: center center;
  mask-position: center center;
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  background-color: currentColor;

  &.success {
    background-color: var(--theme-success);
  }
  &.error {
    background-color: var(--theme-error);
  }

  &.sm {
    width: 12px;
    height: 12px;
  }

  &.md {
    width: 20px;
    height: 20px;
  }

  &.lg {
    width: 44px;
    height: 44px;
  }

  &.home {
    mask-image: url('~./../../assets/home.svg');
  }
  &.portfolio {
    mask-image: url('~./../../assets/portfolio.svg');
  }

  &.specifications {
    mask-image: url('~./../../assets/specifications.svg');
  }

  &.users {
    mask-image: url('~./../../assets/users.svg');
  }

  &.forms {
    mask-image: url('~./../../assets/forms.svg');
  }

  &.tools {
    mask-image: url('~./../../assets/tools.svg');
  }
  &.download {
    mask-image: url('~./../../assets/download.svg');
  }
  &.left-left {
    mask-image: url('~./../../assets/left-left.svg');
  }
  &.right-right {
    mask-image: url('~./../../assets/right-right.svg');
  }
  &.help {
    mask-image: url('~./../../assets/help.svg');
  }
  &.categorization {
    mask-image: url('~./../../assets/categorization.svg');
  }
  &.remove {
    mask-image: url('~./../../assets/remove.svg');
  }
  &.delete {
    mask-image: url('~./../../assets/delete.svg');
  }
  &.word {
    mask-image: url('~./../../assets/word.svg');
  }
  &.visio {
    mask-image: url('~./../../assets/visio.svg');
  }
  &.messages {
    mask-image: url('~./../../assets/messages.svg');
  }
  &.settings {
    mask-image: url('~./../../assets/settings.svg');
  }
  &.workflow {
    mask-image: url('~./../../assets/workflow.svg');
  }
  &.robot {
    mask-image: url('~./../../assets/robot.svg');
  }
  &.script {
    mask-image: url('~./../../assets/script.svg');
  }
  &.email {
    mask-image: url('~./../../assets/email.svg');
  }
}
</style>
