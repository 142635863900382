<template>
  <div v-if="!creationMode" class="milestones">
    <p-loading v-if="isGetCollectionRequestPending" />
    <template v-else>
      <div class="top-line">
        <router-link to="/workflows">Go back to workflows</router-link>
        <p-button v-if="$hasPermission('workflows')" color="primary" @click="addMilestone">Add milestone template</p-button>
        <p-button v-if="$hasPermission('workflows')" color="secondary" @click="onImport({})">Import milestone template</p-button>
      </div>
      <div class="milestones-wrapper">
        <template v-if="collection.length">
          <milestone
            v-for="(milestone, index) in collection"
            :key="milestone.id"
            :order="index + 1"
            :milestone="milestone"
            @delete="onDelete(milestone.id)"
            @edit="onEdit(milestone)"
            @clone="onClone(milestone)"
            @export="onExport(milestone)"
            @import="onImport(milestone)"
          />
        </template>
        <div v-else class="empty-milestones">No milestones created</div>
      </div>
      <milestone-edit-popup v-if="editedMilestone" :milestone="editedMilestone" @cancel="editedMilestone = null" />
      <export-modal v-if="importModal" :milestone="importModal" @cancel="importModal = null" @import="onImported" />
    </template>
  </div>
  <milestone-wizard v-else class="milestones" @close="onWizardClose" @done="onMilestoneAdded" />
</template>
<script>
import { mapState } from 'vuex';
import Button from '../common/Button.vue';
import MilestoneWizard from './MilestoneWizard.vue';
import MilestoneEditPopup from './MilestoneEditPopup.vue';
import ExportModal from './ImportModal.vue';
import Loading from '@/components/common/Loading';

import Milestone from './Milestone.vue';
export default {
  components: {
    Milestone,
    'p-button': Button,
    MilestoneWizard,
    MilestoneEditPopup,
    ExportModal,
    'p-loading': Loading
  },
  data() {
    return {
      creationMode: false,
      editedMilestone: null,
      importModal: null
    };
  },
  computed: {
    ...mapState({
      collection: s => s.milestones.collection,
      isGetCollectionRequestPending: s => s.milestones.isGetCollectionRequestPending
    })
  },
  async created() {
    await Promise.all([this.$store.dispatch('milestones/getCollection', this.$route.params.id), this.$store.dispatch('milestones/getContracts')]);
  },
  methods: {
    addMilestone() {
      this.creationMode = true;
    },
    onMilestoneAdded() {
      this.creationMode = false;
      this.$store.dispatch('milestones/getCollection', this.$route.params.id);
    },
    onWizardClose() {
      this.creationMode = false;
    },
    onEdit(editedMilestone) {
      this.editedMilestone = editedMilestone;
    },
    async onClone(milestone) {
      const { title, createdBy, createdAt, id, ...rest } = milestone;

      try {
        await this.$store.dispatch('milestones/create', {
          workflowId: this.$route.params.id,
          data: {
            ...rest,
            title: `${title}(Clone)`
          }
        });
        this.$store.dispatch('milestones/getCollection', this.$route.params.id);
      } catch (e) {
        this.$toast.error({
          title: 'Milestone clone failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async onDelete(milestoneId) {
      const confirmResult = await this.$confirm({
        title: 'Delete milestone?',
        message: `Are you sure you want to delete milestone?`,
        confirm: 'Delete'
      });
      if (confirmResult) {
        try {
          await this.$store.dispatch('milestones/delete', milestoneId);
        } catch (e) {
          this.$toast.error({
            title: 'Delete failed',
            message: `Please, try again later or contact our development team.`
          });
        }
      }
    },
    async onExport(milestone) {
      const { workflowId, createdAt, createdBy, updatedBy, updatedAt, id, ...other } = milestone;
      await navigator.clipboard.writeText(JSON.stringify(other));
      this.$toast.success({
        message: `Import string copied to clipboard`
      });
    },
    onImport(milestone) {
      this.importModal = milestone;
    },
    async onImported(str) {
      try {
        const data = JSON.parse(str);
        const method = this.importModal.id ? 'update' : 'create';
        const payload = {
          workflowId: this.$route.params.id,
          data
        };
        if (this.importModal.id) {
          payload.milestoneId = this.importModal.id;
        }
        try {
          await this.$store.dispatch(`milestones/${method}`, payload);
        } catch (e) {
          this.$toast.error({
            title: 'Import failed',
            message: 'Contact our developers or try again later.'
          });
        }

        this.$toast.success({
          title: 'Import successful',
          message: `Milestone was ${method}d`
        });
      } catch (e) {
        this.$toast.error({
          title: 'Import failed',
          message: `Reason: import string is invalid`
        });
      } finally {
        this.importModal = null;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.milestones {
  width: 100%;
  padding: 0 20px;
  .top-line {
    margin-bottom: 20px;
    * {
      margin-right: 20px;
    }
  }
  .empty-milestones {
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .milestones-wrapper {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 15px;
  }
}
</style>
