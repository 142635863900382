<template>
  <div class="milestone">
    <div class="title">
      <div class="order-marker">{{ order }}</div>
      <div class="buttons-wrapper">
        <p-button
          v-if="$hasPermission('workflows.read')"
          variant="text"
          title="Export this workflow"
          style="font-size: 1.5rem"
          @click="$emit('export')"
        >
          <md-icon size="sm" name="application-export" />
        </p-button>
        <p-button v-if="$hasPermission('workflows')" variant="text" title="Import workflow" style="font-size: 1.5rem" @click="$emit('import')">
          <md-icon size="sm" name="application-import" />
        </p-button>
        <p-button variant="text" class="" title="Edit this workflow" style="font-size: 1.5rem" @click="$emit('edit')"> &#9998;</p-button>
        <p-button v-if="$hasPermission('workflows')" variant="text" title="Delete this workflow" style="font-size: 1.5rem" @click="$emit('delete')"
          >&times;</p-button
        >
        <p-button v-if="$hasPermission('workflows')" variant="text" title="Clone this workflow" style="font-size: 1.5rem" @click="$emit('clone')"
          >&#x2398;</p-button
        >
      </div>
    </div>
    <router-link :to="`/workflows/${milestone.workflowId}/milestones/${milestone.id}`"> {{ milestone.title }}</router-link>
  </div>
</template>
<script>
import Button from '@/components/common/Button';
import MdIcon from '@/components/common/MdIcon';
export default {
  components: {
    'p-button': Button,
    MdIcon
  },
  props: {
    milestone: {
      type: Object,
      default: () => {}
    },
    order: {
      type: Number,
      default: 1
    }
  }
};
</script>

<style lang="scss" scoped>
.milestone {
  border: 1px solid #fff;
  margin-right: 50px;
  margin-bottom: 20px;
  padding: 10px;
  padding-top: 0;
  position: relative;
  &:hover {
    .title {
      button {
        visibility: visible;
      }
    }
  }
  .buttons-wrapper {
    display: flex;
    align-items: center;
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    button {
      visibility: hidden;
    }
  }
  .order-marker {
    border-radius: 50%;
    background-color: var(--theme-primary);
    flex: 0 0 20px;
    height: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  &:last-child {
    margin-right: 0;
    &:after {
      display: none;
    }
  }
}
</style>
