<template>
  <div class="wrapper">
    <component :is="'style'" type="text/css">{{ eventSourcesStyles }}</component>
    <component :is="'style'" type="text/css">{{ hiddenPathsStyles }}</component>
    <div class="buttons-panel">
      <h1>{{ milestone.title }}</h1>
      <div class="buttons-wrapper">
        <div>
          <p-button color="secondary" @click="goBack">Back</p-button>
          <p-button v-if="$hasPermission('workflows')" color="primary" :disabled="!hasChanges" @click="save">Save</p-button>
          <p-button variant="text" @click="toggleVariablesPopup">Variables</p-button>
          <p-button variant="text" @click="toggleRoadmapPopup">
            {{ milestone.roadmap && milestone.roadmap.length ? 'Edit' : 'Define' }} roadmap
          </p-button>
          <roadmap v-if="milestone.roadmap && milestone.roadmap.length" :roadmap="milestone.roadmap" />
        </div>
        <span v-if="shortestDistance != null">Shortest planned duration: {{ shortestDistance }} hours</span>
        <span v-else>Path does not exists</span>
      </div>
    </div>
    <div class="filter">
      <span class="title">Show connections: </span>
      <div class="checkbox-wrapper">
        <p-checkbox v-model="showConnection.workflow" label="Workflow" />
      </div>
      <div class="checkbox-wrapper">
        <p-checkbox v-model="showConnection.eventSource" label="Event Sources" />
      </div>
    </div>
    <div class="dock"></div>
    <div ref="wrapper" :class="{ 'hide-eventSources': !showConnection.eventSource, 'hide-workflow': !showConnection.workflow }" class="wrp"></div>
    <variables-modal v-if="variablesPopupShown" :variables="milestone.variables || []" @close="toggleVariablesPopup" />
    <roadmap-modal v-if="roadmapModalShown" :roadmap="milestone.roadmap" :steps="allSteps" @close="toggleRoadmapPopup" @save="saveRoadmap" />
  </div>
</template>
<script>
import Rete from 'rete';
import { mapState } from 'vuex';

import createComponents from './utils/createComponents';
import registerPlugins from './utils/registerPlugins';
import addSubscriptions from './utils/addSubscriptions';
import payload from './utils/payload';
import CheckBox from '@/components/common/Checkbox';
import { dijkstra } from './utils/dijkstra';
import Button from '@/components/common/Button';
import VariablesModal from './VariablesModal.vue';
import RoadmapModal from './RoadmapModal.vue';
import Roadmap from './Roadmap.vue';
import { zoomAt } from './utils/zoom';

export default {
  components: {
    'p-checkbox': CheckBox,
    'p-button': Button,
    VariablesModal,
    RoadmapModal,
    Roadmap
  },
  data() {
    return {
      editor: null,
      variablesPopupShown: false,
      addedEventSources: [],
      showConnection: {
        eventSource: true,
        workflow: true
      },
      hiddenPaths: [],
      shortestDistance: 0,
      roadmapModalShown: false,
      hasChanges: false
    };
  },
  computed: {
    ...mapState({
      milestone: s => s.milestones.item,
      eventSources: s => s.milestones.contracts.eventSources,
      miscellaneous: s => s.milestones.contracts.miscellaneous,
      steps: s => s.milestones.templates.steps
    }),
    stepTypes: function() {
      return Object.values(this.steps).map(step => step.type);
    },
    typesMap: function() {
      return this.steps.reduce((acc, curr) => {
        acc[curr.type] = curr.ports;
        return acc;
      }, {});
    },
    eventSourcesStrings() {
      return this.eventSources.map(e => e.source);
    },
    eventSourcesStyles() {
      return this.addedEventSources.map(s => `.wrapper .dock .dock-item .event-source.${s} { display: none; }`).join(' ');
    },
    hiddenPathsStyles() {
      return this.hiddenPaths
        .map(
          s => `.connection.input-${s},
          .connection.input-${s} + div,
          .connection.output-${s},
          .connection.output-${s} + div { display: none;  }`
        )
        .join(' ');
    },
    allSteps() {
      const data = this.editor.toJSON();
      return Object.values(data.nodes).map(p => ({ id: p.id, title: p.data && p.data.props.title }));
    }
  },
  async mounted() {
    const container = this.$refs.wrapper;
    this.editor = new Rete.NodeEditor('demo@0.1.0', container);

    registerPlugins(this.editor);

    const start = this.eventSources.find(source => source.source === 'milestone-start');

    createComponents(this.editor, this.eventSources, this.steps, start.source, this.miscellaneous);
    const data = payload.importData(this.milestone, this.eventSources, this.typesMap);

    this.editor.on('renderconnection', ({ connection, el }) => {
      el.children[0].classList.add(`event-source-${connection.output.node.data.props.source}`);

      el.children[0].classList.add(`input-${connection.input.node.id}`);
      el.children[0].classList.add(`output-${connection.output.node.id}`);
      if (connection.output.node.data.props.isEventSource) {
        el.children[0].classList.add('event-source');
      }
    });

    this.editor.on('noderemove', node => {
      const index = this.addedEventSources.findIndex(s => s === node.name);
      if (index > -1) {
        this.addedEventSources.splice(index, 1);
      }
    });

    this.editor.on('nodecreate', node => {
      if (this.eventSourcesStrings.includes(node.name)) {
        this.addedEventSources.push(node.name);
      }
    });
    await this.editor.fromJSON(data);

    this.editor.bind('connectionVisibilityChanged');
    this.editor.on('connectionVisibilityChanged', ({ id, value }) => {
      const index = this.hiddenPaths.findIndex(p => p === id);
      if (index > -1) {
        this.hiddenPaths.splice(index, 1);
      } else {
        this.hiddenPaths.push(id);
      }
    });

    addSubscriptions(this.editor, this);
    this.editor.on('stepDurationChanged nodecreated connectioncreated noderemoved connectionremoved', () => {
      this.calculateDistances();
    });
    this.calculateDistances();
    zoomAt(this.editor);
    await this.fillCountObjects();
  },

  methods: {
    calculateDistances() {
      const { distances } = dijkstra(this.graphRepresentation(), 'start');
      this.shortestDistance = distances.finish === Infinity ? undefined : distances.finish.toFixed(1);
    },
    graphRepresentation() {
      if (!this.editor) {
        return [];
      }
      const data = this.editor.toJSON();
      if (!data.nodes) {
        return [];
      }
      // const createEvent = this.milestone.events.find(e => e.type === 'create');
      const start = this.eventSources.find(source => source.source === 'milestone-start');

      const entries = Object.entries(data.nodes).filter(node => node[0] !== 'nowhere');
      const finish = entries.find(e => e[1].name === 'finish');
      return entries.reduce((acc, node) => {
        let key = node[0];
        if (node[1].name === start.source) {
          key = 'start';
        }
        if (node[1].name === 'finish') {
          key = 'finish';
        }

        if (!acc[key]) {
          acc[key] = {};
        }

        Object.values(node[1].outputs)
          .filter(o => o.connections.length)
          .forEach(output => {
            output.connections.forEach(connection => {
              acc[key][connection.node === finish[1].id ? 'finish' : connection.node] = node[1].data.props.durationHours
                ? +node[1].data.props.durationHours
                : 0.0;
            });
          });
        return acc;
      }, {});
    },
    async save() {
      const distances = dijkstra(this.graphRepresentation(), 'start');
      if (distances.distances.finish === Infinity) {
        this.$toast.error({
          title: 'Error',
          message: `Finish node is unreachable`
        });
        return;
      }
      const editorData = this.editor.toJSON();
      const { steps, paths, eventSources } = payload.exportData(editorData, this.stepTypes);
      try {
        await this.$store.dispatch('milestones/update', {
          workflowId: this.$route.params.workflowId,
          milestoneId: this.milestone.id,
          data: { ...this.milestone, steps, paths, eventSources }
        });
        this.$toast.success({
          title: 'Update completed',
          message: `Milestone successfully updated`
        });
        this.hasChanges = false;
      } catch (e) {
        this.$toast.error({
          title: 'Update failed',
          message: `Please, try again later or contact our development team.`
        });
      }
    },
    async goBack() {
      const editorData = this.editor.toJSON();
      const { steps, paths, eventSources } = payload.exportData(editorData, this.stepTypes);
      const stepsHasChanges = JSON.stringify(this.milestone.steps) !== JSON.stringify(steps);
      const pathsHasChanges = JSON.stringify(this.milestone.paths) !== JSON.stringify(paths);
      const eventSourcesHasChanges = JSON.stringify(this.milestone.eventSources) !== JSON.stringify(eventSources);
      if (this.hasChanges && (stepsHasChanges || pathsHasChanges || eventSourcesHasChanges)) {
        const confirmResult = await this.$confirm({
          title: 'You have unsaved changes.',
          message: `Are you sure you want to leave page? All changes will be lost.`,
          confirm: 'Leave anyway'
        });
        if (!confirmResult) {
          return;
        }
      }
      this.$router.push(`/workflows/${this.$route.params.workflowId}`);
    },
    toggleVariablesPopup() {
      this.variablesPopupShown = !this.variablesPopupShown;
    },
    toggleRoadmapPopup() {
      this.roadmapModalShown = !this.roadmapModalShown;
    },
    saveRoadmap(roadmap) {
      this.roadmapModalShown = false;
      const allInserted = roadmap.reduce((acc, curr) => {
        acc.push(...curr.steps);
        return acc;
      }, []);
      this.editor.nodes.forEach(node => {
        node.data.props.isAddedToRoadMap = allInserted.includes(node.id);
      });
      this.hasChanges = true;
      this.$store.commit('milestones/ROADMAP_CHAGED', roadmap);
    },
    async fillCountObjects() {
      const promises = [];
      this.editor.nodes.forEach(node => {
        if (node.data.props.isLoadingCount) {
          promises.push(this.getTaskCount(node));
        }
      });
      await Promise.all(promises);
    },
    async getTaskCount(node) {
      const { workflowId, milestoneId } = this.$route.params;

      const counts = await this.$store.dispatch('milestones/getStepTasksCount', { workflowId, milestoneId, stepId: node.id });
      counts.forEach(count => {
        node.data.props.tasksCount[count.status] = count.count;
      });
      node.data.props.isLoadingCount = false;
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  width: 100%;
  height: 100%;
}

.buttons-panel {
  margin: 10px 0;
  padding: 0 10px;
  height: 60px;
  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
    }
    button {
      margin-right: 10px;
    }
  }
}

.filter {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  .checkbox-wrapper {
    margin: 0 5px;
    display: flex;
    align-items: center;
  }
}
.dock {
  height: 70px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
}
</style>
<style lang="scss">
.wrp {
  height: calc(95% - 150px);

  .connection {
    .main-path {
      stroke-width: 3px;
    }
    &.input-nowhere {
      display: none;
    }
  }
  &.hide-eventSources {
    .connection {
      &.event-source {
        display: none;
      }

      &.event-source + div {
        display: none;
      }
    }
  }
  &.hide-workflow {
    .connection {
      &.event-source-workflow {
        display: none;
      }

      &.event-source-workflow + div {
        display: none;
      }
    }
  }
}
.tile {
  &.nowhere {
    display: none;
  }
}

.dock-item {
  display: inline-block;
  vertical-align: top;
  transform: scale(0.8);
  transform-origin: 50% 0;
  .tile {
    .statuses-wrapper {
      display: none;
    }
    &.start,
    &.finish {
      display: none;
    }
  }
}
.connection {
  &.event-source-workflow {
    path {
      stroke: yellow;
    }
  }
}
</style>
