<template>
  <Editor v-if="!isLoading" />
</template>
<script>
import { mapState } from 'vuex';
import Editor from './Editor';

export default {
  components: {
    Editor
  },
  computed: {
    ...mapState({
      milestone: s => s.milestones.item,
      isLoading: s => s.milestones.isGetRequestPending || s.milestones.isContractsRequestPending || s.milestones.isTemplatesRequestPending
    })
  },
  async created() {
    const { workflowId, milestoneId } = this.$route.params;
    await Promise.all([
      this.$store.dispatch('milestones/getById', { workflowId, milestoneId }),
      this.$store.dispatch('milestones/getContracts'),
      this.$store.dispatch('milestones/getTemplates')
    ]);
  }
};
</script>
