import { factory, am } from './utils';
import httpClient from '@/utils/httpClient';

const GET_CONTRACTS = am(`GET_CONTRACTS`);
const GET_TEMPLATES = am(`GET_TEMPLATES`);

export default {
  namespaced: true,
  ...factory({
    state: {
      contracts: [],
      isContractsRequestPending: false,
      isTemplatesRequestPending: false,
      templates: {}
    },
    mutations: {
      [GET_CONTRACTS.STARTED](state) {
        state.isContractsRequestPending = true;
      },
      [GET_CONTRACTS.COMPLETED](state, contracts) {
        state.isContractsRequestPending = false;
        state.contracts = contracts;
      },
      [GET_TEMPLATES.STARTED](state) {
        state.isTemplatesRequestPending = true;
      },
      [GET_TEMPLATES.COMPLETED](state, templates) {
        state.templates = templates;
        state.isTemplatesRequestPending = false;
      },
      VARIABLES_CHANGED(state, { variables, source }) {
        const milestone = state.item;

        const existing = milestone.variables || [];

        if (source) {
          const filtered = existing.filter(variable => {
            if (!variable.source) {
              return true;
            }
            const exists = variable.source.port === source.port && variable.source.step === source.step;
            return !exists;
          });
          state.item = { ...milestone, variables: [...filtered, ...variables] };
        } else {
          state.item = { ...milestone, variables };
        }
      },
      ROADMAP_CHAGED(state, roadmap) {
        state.item.roadmap = roadmap;
      }
    },
    actions: {
      async getContracts({ commit }) {
        commit(GET_CONTRACTS.STARTED);
        const data = await httpClient.get(`/api/workflows/v2/workflows/settings/contracts`);
        commit(GET_CONTRACTS.COMPLETED, data);
      },
      async getTemplates({ commit }) {
        commit(GET_TEMPLATES.STARTED);
        const data = await httpClient.get(`/api/workflows/v2/workflows/settings/templates`);
        commit(GET_TEMPLATES.COMPLETED, data);
      },
      async getStepTasksCount(tech, { workflowId, milestoneId, stepId }) {
        try {
          return httpClient.get(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}/steps/${stepId}/statistics`);
        } catch (e) {
          return {};
        }
      },
      async getWorkflowByReferences(tech, references) {
        let workflow;
        try {
          workflow = await httpClient.get(`/api/workflows/v2/workflows/by/references?${references.map(r => 'references=' + r).join('&')}`);
        } catch (e) {}
        if (!workflow) {
          workflow = await httpClient.get(`/api/workflows/v2/workflows/by/default`);
        }
        return httpClient.get(`/api/workflows/v2/workflows/${workflow.id}/milestones`);
      },
      async createInstance(tech, payload) {
        return httpClient.post(`/api/workflows/milestones`, payload);
      }
    }
  })('milestones', {
    async getById({ workflowId, milestoneId }) {
      const collection = await httpClient.get(`/api/workflows/v2/workflows/${workflowId}/milestones`);
      const milestone = collection.find(milestone => milestone.id === milestoneId);
      return milestone;
    },
    async getCollection(workflowId) {
      return httpClient.get(`/api/workflows/v2/workflows/${workflowId}/milestones`);
    },
    async create({ workflowId, data }) {
      return httpClient.post(`/api/workflows/v2/workflows/${workflowId}/milestones`, data);
    },
    async update(smthng, { workflowId, milestoneId, data }) {
      return httpClient.put(`/api/workflows/v2/workflows/${workflowId}/milestones/${milestoneId}`, data);
    },
    async delete(id) {
      return httpClient.delete(`/api/workflows/v2/workflows/milestones/${id}`);
    }
  })
};
