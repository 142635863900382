import { typeStatusesMap } from '../rete-components/Component';
const supportedTypes = Object.keys(typeStatusesMap);

const createPorts = (statuses, suffix) => {
  return statuses.reduce((i, status) => {
    i[`${status.port ? status.port : status}____${suffix}`] = { connections: [] };
    return i;
  }, {});
};

const createOutputs = statuses => {
  return createPorts(statuses, 'output');
};

const createInputs = statuses => {
  return createPorts(statuses, 'input');
};

const createPortsCount = statuses => {
  return statuses.reduce((acc, curr) => {
    acc[curr] = 0;
    return acc;
  }, {});
};

const createPaths = (paths = [], inputs, outputs, itemId) => {
  paths
    .filter(step => step.to.step === itemId || (itemId == 100500 && step.to.step === 'nowhere'))
    .reduce((inpAcc, path) => {
      const key = `${path.to.port}____input`;
      inpAcc[key].connections.push({
        data: {
          pins: path.display ? path.display.pins : []
        },
        node: path.from.step,
        output: `${path.from.port}____output`
      });
      return inpAcc;
    }, inputs);
  paths
    .filter(step => step.from.step === itemId)
    .reduce((outAcc, path) => {
      const key = `${path.from.port}____output`;
      outAcc[key].connections.push({
        data: {
          pins: path.display ? path.display.pins : []
        },
        node: path.to.step === 'nowhere' ? 100500 : path.to.step,
        input: `${path.to.port}____input`
      });
      return outAcc;
    }, outputs);
};

const fillPathsWithData = (paths = [], nodes) => {
  paths.forEach(path => {
    if (path.to.action) {
      const inputTarget = nodes[path.to.step];
      const targetConnections = inputTarget.inputs[`${path.to.port}____input`].connections;
      const targetConnection = targetConnections.find(c => {
        return c.node === path.from.step && c.output === `${path.from.port}____output`;
      });
      targetConnection.data.to = path.to.action;

      const outputTarget = nodes[path.from.step];
      const outTargetConnections = outputTarget.outputs[`${path.from.port}____output`].connections;
      const outTargetConnection = outTargetConnections.find(c => {
        return c.node === path.to.step && c.input === `${path.to.port}____input`;
      });
      outTargetConnection.data.to = path.to.action;
    }
    if (path.from.action) {
      const a = nodes[path.from.step];
      const b = a.outputs[`${path.from.port}____output`].connections;
      const o = b.find(c => {
        const step = path.to.step === 'nowhere' ? 100500 : path.to.step;
        return c.node === step && c.input === `${path.to.port}____input`;
      });

      o.data.from = path.from.action.form;

      const g = nodes[path.to.step === 'nowhere' ? 100500 : path.to.step];
      const h = g.inputs[`${path.to.port}____input`].connections;

      const op = h.find(c => {
        return c.node === path.from.step && c.output === `${path.from.port}____output`;
      });
      op.data.from = path.from.action.form;
    }
  });
};

const importData = ({ steps = [], paths = [], eventSources = [], roadmap = [] }, eventSourcesMapping, typesMap) => {
  const start = eventSourcesMapping.find(e => e.source === 'milestone-start');

  if (!steps.length) {
    const outputs = createOutputs(start.events.map(e => e.code));
    createPaths([], {}, outputs, 1);
    return {
      id: 'demo@0.1.0',
      nodes: {
        1: {
          id: 1,
          data: {
            props: { type: start.source, title: start.source, source: start.source, isEventSource: true, durationHours: 0, notDeletable: true }
          },
          outputs,
          position: [35.19727061858006, 180.07201672658843],
          name: start.source
        },
        2: {
          id: 2,
          data: { props: { type: 'finish', title: 'FINISH', notDeletable: true, durationHours: 0 } },
          inputs: { 'Finish-input': { connections: [] } },
          position: [1175.0399169921875, 151.55126953125],
          name: 'finish'
        },
        100500: {
          id: 100500,
          data: { props: { type: 'nowhere', title: 'nowhere', notDeletable: true, durationHours: 0 } },
          inputs: { 'nowhere-input': { connections: [] } },
          position: [1, 1],
          name: 'nowhere'
        }
      }
    };
  }
  steps = [
    ...steps,

    {
      durationHours: 0,
      id: 100500,
      isCriticalDate: false,
      options: {},
      display: { position: [1, 1] },
      title: 'nowhere',
      transitions: [],
      type: 'nowhere'
    }
  ];
  const nodes = steps.reduce((acc, curr) => {
    const allTypes = { ...typeStatusesMap, ...typesMap };
    if (!allTypes[curr.type]) {
      return acc;
    }

    const inputs = createInputs(allTypes[curr.type]);
    const outputs = createOutputs(allTypes[curr.type]);
    createPaths(paths, inputs, outputs, curr.id);
    const isAddedToRoadMap = roadmap.some(s => s.steps.includes(curr.id));
    const step = {
      id: curr.id === 'nowhere' ? 100500 : curr.id,
      data: {
        props: {
          type: curr.type,
          title: curr.title,
          source: 'workflow',
          transitions: curr.transitions || [],
          notDeletable: curr.type === 'finish',
          durationHours: curr.type === 'finish' ? 0 : curr.durationHours || 1,
          tasksCount: curr.type === 'finish' || curr.type === 'nowhere' ? {} : createPortsCount(typesMap[curr.type]),
          isLoadingCount: curr.type === 'finish' ? null : true,
          isAddedToRoadMap,
          options: {
            notifications: {
              email: curr.options && curr.options.notifications && curr.options.notifications.email
            },
            instructions: (curr.options && curr.options.instructions) || ''
          },
          isCriticalDate: curr.isCriticalDate || false
        }
      },
      name: curr.type,
      inputs,
      outputs,
      position: curr.display.position
    };
    acc[curr.id] = step;
    return acc;
  }, {});

  if (eventSources) {
    eventSources.forEach(eventSource => {
      const source = eventSourcesMapping.find(s => s.source === eventSource.type);
      const outputs = createOutputs(source.events.map(e => e.code));
      createPaths(paths, {}, outputs, eventSource.id);
      const step = {
        id: eventSource.id,
        data: {
          props: {
            type: eventSource.type,
            title: eventSource.title,
            source: eventSource.type,
            isEventSource: true,
            tasksCount: {},
            notDeletable: eventSource.type === start.source
          }
        },
        name: eventSource.type,
        outputs,
        position: eventSource.display.position
      };
      nodes[eventSource.id] = step;
    });
  }

  fillPathsWithData(paths, nodes);
  return {
    id: 'demo@0.1.0',
    nodes
  };
};

const exportData = (editorData, stepTypes) => {
  const data = Object.values(editorData.nodes)
    .filter(n => n.id !== 100500)
    .reduce(
      (acc, node) => {
        const step = {
          display: {
            position: node.position
          },
          transitions: node.data.props.transitions,
          title: node.data.props ? node.data.props.title : node.name,
          type: node.name,
          id: node.id,
          durationHours: node.data.props.durationHours || 1,
          options: node.data.props.options,
          isCriticalDate: node.data.props.isCriticalDate
        };
        if ([...supportedTypes, ...stepTypes].includes(node.name)) {
          acc.steps.push(step);
        } else {
          acc.eventSources.push(step);
        }
        const existingPaths = Object.entries(node.outputs).filter(([key, value]) => value.connections.length);
        const paths = existingPaths.reduce((pathsAcc, [name, paths]) => {
          const pathCollection = paths.connections.map(path => {
            return {
              from: {
                step: node.id,
                port: name.split('____')[0],
                action: path.data.from ? { form: path.data.from } : null
              },
              to: { step: path.node === 100500 ? 'nowhere' : path.node, port: path.input.split('____')[0], action: path.data.to },
              display: {
                pins: path.data.pins
              }
            };
          });

          pathsAcc.push(...pathCollection);
          return pathsAcc;
        }, []);
        acc.paths.push(...paths);
        return acc;
      },
      {
        steps: [],
        paths: [],
        eventSources: []
      }
    );

  return data;
};

const payload = { importData, exportData };

export default payload;
