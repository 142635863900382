<template>
  <div>
    <div v-if="value.length" class="row">Conditions:</div>
    <div v-for="(predicatesArr, arrIndex) in localPredicates" :key="arrIndex" class="predicates-group">
      <div class="header">
        <span>Conditions group</span>
        <p-button variant="text" style="font-size: 2rem" @click="onGroupRemove(arrIndex)"><md-icon name="close"/></p-button>
      </div>
      <div v-for="(predicate, index) in predicatesArr" :key="index" class="row ">
        <div class="predicate">
          <div>
            <p-multiselect
              :disabled="disabled"
              :value="predicate.extendedValue"
              :options="availableContracts"
              :get-label-callback="s => s.name"
              @input="$e => onFieldInput($e, index, arrIndex)"
            />
          </div>
          <div>
            <p-multiselect
              v-if="predicate.extendedValue.type"
              :disabled="disabled"
              :value="predicate.function"
              :options="functionsTypesMap[predicate.extendedValue.type]"
              :get-label-callback="s => s.name"
              @input="$e => onFunctionInput($e, index, arrIndex)"
            />
          </div>
          <p-button variant="text" style="font-size: 2rem" @click="onRemove(index, arrIndex)"><md-icon name="close"/></p-button>
        </div>
        <div class="row subrow">
          <template v-if="predicate.function.name && predicate.function.arguments.length">
            <p-text-field
              v-if="predicate.extendedValue.type === 'string' || predicate.extendedValue.type === 'number'"
              v-model="predicate.value"
              :disabled="disabled"
              @input="onInput"
            />
            <p-assignees
              v-if="predicate.extendedValue.type === 'people'"
              v-model="predicate.value"
              :disabled="disabled"
              :code="code"
              :source="source"
              @input="onInput"
            />
            <p-due-at
              v-if="predicate.extendedValue.type === 'date'"
              v-model="predicate.value"
              :disabled="disabled"
              :code="code"
              :source="source"
              @input="onInput"
            />
          </template>
        </div>
      </div>
      <p-button :disabled="disabled" color="primary" @click="addPredicate(arrIndex)">Add condition</p-button>
    </div>
    <p-button :disabled="disabled" color="primary" @click="addPredicatesGroup">Add conditions group</p-button>
  </div>
</template>
<script>
import Button from '@/components/common/Button';
import TextField from '@/components/common/TextField';
import Multiselect from '@/components/common/Multiselect';
import MdIcon from '@/components/common/MdIcon.vue';
import AssigneesWithVariables from '../../workflow-editor/parts/AssigneesWithVariables.vue';
import DueDateWithVariables from '../../workflow-editor/parts/DueDateWithVariables.vue';

import { mapState } from 'vuex';
import { mapVariablesFromContracts } from '../../workflow-editor/parts/utils';

export default {
  components: {
    'p-button': Button,
    'p-text-field': TextField,
    'p-multiselect': Multiselect,
    'p-assignees': AssigneesWithVariables,
    'p-due-at': DueDateWithVariables,
    MdIcon
  },
  props: {
    code: {
      type: [String, Array],
      default: null
    },
    source: {
      type: [String, Array],
      default: null
    },
    value: {
      type: Array,
      default: () => []
    },
    additionalVariables: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean
    }
  },
  data() {
    return {
      localPredicates: []
    };
  },
  computed: {
    ...mapState({
      contracts: s => s.milestones.contracts
    }),
    availableContracts: function() {
      if (!this.code) {
        return [...this.additionalVariables];
      }
      let variables = [];
      if (Array.isArray(this.source)) {
        variables = this.source.reduce((acc, curr, sourceIndex) => {
          const vars = mapVariablesFromContracts(this.contracts, curr, this.code[sourceIndex], undefined, undefined, undefined, true);
          vars.forEach(v => {
            const exists = acc.find(av => av.name === v.name);
            if (!exists) {
              acc.push(v);
            }
          });
          return acc;
        }, []);
      } else {
        variables = mapVariablesFromContracts(this.contracts, this.source, this.code, undefined, undefined, undefined, true);
      }
      return [...this.additionalVariables, ...variables];
    },
    functionsTypesMap() {
      return this.contracts.functions.reduce((acc, curr) => {
        curr.types.forEach(type => {
          if (!acc[type]) {
            acc[type] = [{ name: curr.name, arguments: curr.arguments }];
          } else {
            acc[type].push({ name: curr.name, arguments: curr.arguments });
          }
        });
        return acc;
      }, {});
    }
  },
  mounted() {
    let value = [];
    if (this.value && this.value.length) {
      if (Array.isArray(this.value[0])) {
        value = this.value;
      } else {
        value = [this.value];
      }
    }
    value.forEach(v => {
      let arr = [];
      v.forEach(o => {
        const extendedContract = this.availableContracts.find(c => c.name === o.field.slice(2, o.field.length - 2));
        const functionTemplate = this.contracts.functions.find(f => f.name === o.function);
        arr.push({
          function: {
            name: o.function,
            arguments: functionTemplate.arguments
          },
          field: o.field.substring(2, o.field.length - 2),
          extendedValue: extendedContract,
          value: o.arguments[0]
        });
      });
      this.localPredicates.push(arr);
    });
  },
  methods: {
    addPredicate(arrIndex) {
      this.localPredicates[arrIndex].push({ field: '', function: { name: null, arguments: [] }, value: '', extendedValue: { name: '', type: '' } });
    },
    onGroupRemove(arrIndex) {
      this.localPredicates.splice(arrIndex, 1);
      this.onInput();
    },
    onInput() {
      this.$emit(
        'input',
        this.localPredicates.map(lp => {
          return lp.map(p => ({
            field: `{{${p.extendedValue.name}}}`,
            function: p.function.name,
            arguments: [p.value]
          }));
        })
      );
    },
    onRemove(index, arrIndex) {
      this.localPredicates[arrIndex].splice(index, 1);
      if (!this.localPredicates[arrIndex].length) {
        this.localPredicates.splice(arrIndex, 1);
      }
      this.onInput();
    },
    onFieldInput(v, index, arrIndex) {
      this.localPredicates[arrIndex][index].extendedValue = v;
      this.localPredicates[arrIndex][index].value = null;
      this.localPredicates[arrIndex][index].function = { name: null };
      this.onInput();
    },
    onFunctionInput(v, index, arrIndex) {
      const value = this.localPredicates[arrIndex][index];
      value.function.name = v.name;
      value.function.arguments = v.arguments;
      if (value.extendedValue.type === 'date') {
        value.value = {};
      } else if (value.extendedValue.type === 'people') {
        value.value = [];
      } else {
        value.value = null;
      }
      this.onInput();
    },
    addPredicatesGroup() {
      this.localPredicates.push([{ field: '', function: { name: null, arguments: [] }, value: '', extendedValue: { name: '', type: '' } }]);
    }
  }
};
</script>
<style lang="scss" scoped>
.row {
  margin-bottom: 20px;
}
.predicate {
  display: grid;
  grid-template-columns: 1fr 1fr 20px;
  gap: 5px;
}
.subrow {
  padding: 0 25px;
}

.predicates-group {
  border: 1px solid #fff;
  margin: 20px 0;
  padding: 20px;
  .header {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
}
</style>
