<template>
  <form class="file-select">
    <p-button :color="color" :variant="variant" type="button" :disabled="disabled" @click="openDialog">
      <slot>Upload</slot>
    </p-button>
    <input :id="id" type="file" :accept="accept" :diabled="disabled" @input="input" />
  </form>
</template>

<script>
import Button from './Button';
export default {
  components: {
    'p-button': Button
  },
  props: {
    id: {
      type: String,
      default: () => Math.random().toString()
    },
    color: {
      type: String,
      default: '',
      validator: function(value) {
        return ['', 'primary', 'secondary'].indexOf(value) !== -1;
      }
    },
    variant: {
      type: String,
      default: 'contained',
      validator(value) {
        return ['contained', 'text'].indexOf(value) !== -1;
      }
    },
    accept: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openDialog() {
      if (this.disabled) {
        return;
      }
      this.$el.reset();
      document.getElementById(this.id).click();
    },
    input(e) {
      this.$emit('input', e.target.files);
      this.$emit('change', e.target.files);
    }
  }
};
</script>

<style lang="scss" scoped>
.file-select {
  input[type='file'] {
    display: none;
  }
}
</style>
