<template>
  <section class="code" :data-language="language"></section>
</template>
<script>
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';

// monaco.languages.registerCompletionItemProvider('handlebars', {
//   triggerCharacters: ['#', '{'],
//   provideCompletionItems: function(model, position) {
//     const suggestions = Array.isArray(completion[model.id])
//       ? completion[model.id].map(s => ({
//           label: s.label,
//           kind: monaco.languages.CompletionItemKind.Function,
//           documentation: s.documentation,
//           insertText: `${s.label} "\${2:${s.arguments}}"`,
//           insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
//         }))
//       : [];

//     return {
//       suggestions
//     };
//   }
// });

const options = {
  theme: 'vs-dark',
  minimap: {
    enabled: false
  },
  scrollbar: {
    verticalScrollbarSize: 6,
    horizontalScrollbarSize: 6
  },
  automaticLayout: true,
  wordWrap: 'on'
};

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    language: {
      type: String,
      default: 'json'
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    suggestions: {
      type: Array,
      default: () => null
    }
  },
  data() {
    return {
      ed: null,
      disaposables: []
    };
  },
  destroyed() {
    for (const disaposable of this.disaposables) {
      disaposable.dispose();
    }
  },
  mounted() {
    this.ed = monaco.editor.create(this.$el, {
      ...options,
      readOnly: this.readOnly,
      language: this.language,
      value: this.value
    });

    if (this.suggestions) {
      const modelId = this.ed.getModel().id;
      const suggestions = this.suggestions;
      this.disaposables.push(
        monaco.languages.registerCompletionItemProvider(this.language, {
          triggerCharacters: ['#', '{'],
          provideCompletionItems: function(model, position) {
            if (model.id !== modelId) {
              return [];
            }

            return {
              suggestions: Array.isArray(suggestions)
                ? suggestions.map(s => ({
                    label: s.label,
                    kind: monaco.languages.CompletionItemKind.Function,
                    documentation: s.documentation,
                    insertText: `${s.label} "\${2:${s.arguments}}"`,
                    insertTextRules: monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                  }))
                : []
            };
          }
        })
      );
    }

    this.ed.addAction({
      id: 'toggle-word-wrap',
      label: 'Toggle Word Wrap',
      precondition: null,
      keybindingContext: null,
      contextMenuGroupId: 'navigation',

      contextMenuOrder: 1.5,
      run: function(ed) {
        options.wordWrap = options.wordWrap === 'on' ? 'off' : 'on';
        ed.updateOptions({ wordWrap: options.wordWrap });
      }
    });
    this.ed.onDidChangeModelContent(this.change);
  },
  methods: {
    change(_value, event) {
      const data = this.ed.getValue();
      this.$emit('change', data, event);
      this.$emit('input', data);
    }
  }
};
</script>

<style scoped>
.code {
  width: 100%;
  height: 100%;
}
</style>
