<template>
  <section class="shell">
    <aside :class="{ expanded }">
      <nav>
        <ul class="nav-list">
          <li class="nav-list-item">
            <router-link to="/" exact
              ><span><p-icon name="home"></p-icon></span>Overview</router-link
            >
          </li>
          <li v-if="$hasPermission('portfolio.read')" class="nav-list-item">
            <router-link to="/portfolio"
              ><span><p-icon name="portfolio"></p-icon></span>Portfolio</router-link
            >
          </li>
          <li v-if="$hasPermission('portfolio.read')" class="nav-list-item">
            <router-link to="/categorization"
              ><span><p-icon name="categorization"></p-icon></span>Categorization</router-link
            >
          </li>
          <li v-if="$hasPermission('users.read')" class="nav-list-item">
            <router-link to="/people"
              ><span><p-md-icon name="account-multiple"></p-md-icon></span>People</router-link
            >
          </li>
          <li v-if="$hasPermission('users.read')" class="nav-list-item">
            <router-link to="/teams"
              ><span><p-md-icon name="account-group"></p-md-icon></span>Teams</router-link
            >
          </li>
          <li v-if="$hasPermission('organizations.read')" class="nav-list-item">
            <router-link to="/organizations"
              ><span><p-md-icon name="office-building"></p-md-icon></span>Organizations</router-link
            >
          </li>
          <li v-if="$hasPermission('specifications.read')" class="nav-list-item">
            <router-link to="/specifications"
              ><span><p-icon name="specifications"></p-icon></span>Specifications</router-link
            >
          </li>
          <li v-if="$hasPermission('templates.read')" class="nav-list-item">
            <router-link to="/rules"
              ><span><p-md-icon name="bug-check-outline"></p-md-icon></span>Rules</router-link
            >
          </li>
          <li v-if="$hasPermission('forms.read')" class="nav-list-item">
            <router-link to="/forms"
              ><span><p-icon name="forms"></p-icon></span>Forms</router-link
            >
          </li>
          <li v-if="$hasPermission('templates.read')" class="nav-list-item">
            <router-link to="/documents/word">
              <span><p-icon name="word"></p-icon></span>Word Templates
            </router-link>
          </li>
          <li v-if="$hasPermission('templates.read')" class="nav-list-item">
            <router-link to="/documents/visio">
              <span><p-icon name="visio"></p-icon></span>Visio Templates
            </router-link>
          </li>
          <li v-if="$hasPermission('templates.read')" class="nav-list-item">
            <router-link to="/shapes">
              <span><p-md-icon name="shape"></p-md-icon></span>Visio Shapes
            </router-link>
          </li>
          <li v-if="$hasPermission('templates.read')" class="nav-list-item">
            <router-link to="/drawings">
              <span><p-md-icon name="drawing-box"></p-md-icon></span>Drawings
            </router-link>
          </li>
          <li v-if="$hasPermission('messages.read')" class="nav-list-item">
            <router-link to="/messages">
              <span><p-icon name="messages"></p-icon></span>Messages
            </router-link>
          </li>
          <li v-if="$hasPermission('uspto.read')" class="nav-list-item">
            <router-link to="/uspto">
              <span><p-md-icon name="file-sync"></p-md-icon></span>USPTO dashboard
            </router-link>
          </li>
          <li v-if="$hasPermission('portfolio.read')" class="nav-list-item">
            <router-link to="/processing">
              <span><p-md-icon name="file-replace"></p-md-icon></span>Portfolio dashboard
            </router-link>
          </li>
          <li class="nav-list-item">
            <router-link to="/applications"
              ><span><p-icon name="tools"></p-icon></span>Applications</router-link
            >
          </li>
          <li v-if="$hasPermission('administration.read')" class="nav-list-item">
            <router-link to="/emails-connector">
              <span><p-icon name="email"></p-icon></span>Emails Connector
            </router-link>
          </li>
          <li v-if="$hasPermission('administration.read')" class="nav-list-item">
            <router-link to="/administration">
              <span><p-icon name="settings"></p-icon></span>Administration
            </router-link>
          </li>
          <li v-if="$hasPermission('workflows.read')" class="nav-list-item">
            <router-link to="/workflows">
              <span><p-icon name="workflow"></p-icon></span>Workflows
            </router-link>
          </li>
          <li v-if="$hasPermission('docketing_tasks.read')" class="nav-list-item">
            <router-link to="/milestones">
              <span><p-md-icon name="sign-direction"></p-md-icon></span>Milestones
            </router-link>
          </li>
        </ul>
      </nav>
      <footer>
        <p-button variant="text" @click="toggleExpanded"
          ><p-icon v-if="expanded" name="left-left"></p-icon><p-icon v-else name="right-right"></p-icon
        ></p-button>
      </footer>
    </aside>
    <section>
      <router-view />
    </section>
  </section>
</template>

<script>
import { lw } from '@/utils/lw';

import Icon from './common/Icon';

import MdIcon from './common/MdIcon';
import Button from './common/Button';

const LOCAL_STORAGE_ASIDE_EXPANDED = 'LOCAL_STORAGE_ASIDE_EXPANDED';

export default {
  components: {
    'p-icon': Icon,
    'p-button': Button,
    'p-md-icon': MdIcon
  },
  data() {
    return {
      expanded: lw.get(LOCAL_STORAGE_ASIDE_EXPANDED)
    };
  },
  methods: {
    toggleExpanded() {
      this.expanded = !this.expanded;
      lw.set(LOCAL_STORAGE_ASIDE_EXPANDED, this.expanded);
    }
  }
};
</script>

<style lang="scss" scoped>
.shell {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  width: 100%;
  height: 100%;

  aside {
    color: var(--theme-on-surface);
    background-color: var(--theme-surface);
    width: 50px;
    overflow: hidden;
    display: grid;
    grid-template-rows: minmax(0, 1fr) 30px;
    nav {
      overflow-x: hidden;
    }
    .nav-list {
      display: grid;
      font-size: 1rem;
      padding: 1rem;
      grid-gap: 0.5rem;
      align-items: center;

      .nav-list-item {
        // text-transform: uppercase;
        height: 30px;

        a {
          display: flex;
          align-items: center;
          width: 100%;
          white-space: nowrap;

          &.router-link-active {
            color: var(--theme-on-background);
            cursor: default;
          }
        }

        span {
          font-size: 1.5rem;
          display: inline-flex;
          margin-right: 5px;
          width: 30px;
          height: 100%;
          align-items: center;
        }
      }
    }

    footer {
      display: flex;
      width: 100%;

      justify-content: center;
      align-items: center;
    }

    &.expanded {
      width: 250px;

      footer {
        justify-content: flex-end;
      }
    }
  }

  aside ~ section {
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    overflow-y: auto;
  }
}
</style>
