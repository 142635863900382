const mapVariables = (arr, type, result = [], prevStr = '', asObject) => {
  const props = type ? arr.filter(p => p.type === type) : arr;
  result.push(
    ...props.map(p => (asObject ? { name: `${prevStr}${prevStr ? '.' : ''}${p.name}`, type: p.type } : `${prevStr}${prevStr ? '.' : ''}${p.name}`))
  );

  const obj = arr.filter(p => p.type === 'object');
  if (obj.length) {
    obj.forEach(o => mapVariables(o.properties, type, result, o.name, asObject));
  }

  return result;
};

export function mapVariablesFromContracts(contracts, source, code, type, contract, excludeContracts, asObject) {
  const eventSource = contracts.eventSources.find(eventSource => eventSource.source === source);

  if (!eventSource) {
    return [];
  }
  const event = eventSource.events.find(event => event.code === code || event.name === code) || eventSource.events[0];
  const types = event.contract
    .filter(c => {
      if (contract) {
        return contract === c.name;
      }
      if (excludeContracts) {
        return !excludeContracts.includes(c.name);
      }
      return true;
    })
    .reduce((acc, curr) => {
      const correspondingType = contracts.types.find(type => type.code === curr.type);
      const op = mapVariables(correspondingType.properties, type, [], '', asObject);
      acc.push(
        ...op.reduce((c, o) => {
          if (asObject) {
            c.push({ type: o.type, name: `${curr.name}.${o.name}` });
            if (type === 'people') {
              c.push({ type: o.type, name: `${curr.name}.${o.name}.assistants` });
            }
          } else {
            c.push(`{{${curr.name}.${o}}}`);
            if (type === 'people') {
              c.push(`{{${curr.name}.${o}.assistants}}`);
            }
          }
          return c;
        }, [])
      );

      return acc;
    }, []);
  return types;
}
