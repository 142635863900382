<template>
  <ul class="tag-list">
    <li v-for="tag in value" :key="tag" class="tag-list-item">
      <span>{{ tag }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.tag-list {
  margin: 0;
  padding: 0.25rem 0 0 0.25rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  .tag-list-item {
    display: inline-flex;
    padding: 0.25rem 0.35rem;
    color: var(--theme-on-surface);
    border: 1px solid var(--theme-on-surface-accent);
    background: var(--theme-surface);
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 0.75rem;
    position: relative;
    line-height: 1;

    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
}
</style>
