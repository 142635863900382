export const am = name => ({
  STARTED: name + '_STARTED',
  COMPLETED: name + '_COMPLETED',
  FAILED: name + '_FAILED'
});

export const factory = addons => (name, methods, modules) => {
  const uppercased = name.toUpperCase();

  const logger = console;

  const GET_COLLECTION = am(`GET_${uppercased}_COLLECTION`);
  const GET_BY_ID = am(`GET_${uppercased}_BY_ID`);
  const CREATE = am(`CREATE_${uppercased}`);
  const UPDATE = am(`UPDATE_${uppercased}`);
  const DELETE = am(`DELETE_${uppercased}`);

  const state = {
    ...addons.state,
    isGetRequestPending: false,
    isGetRequestFailed: false,

    isGetCollectionRequestPending: false,
    isGetCollectionRequestFailed: false,

    isUpdateRequestPending: false,
    isUpdateRequestFailed: false,

    isCreateRequestPending: false,
    isCreateRequestFailed: false,

    isDeleteRequestPending: false,
    isDeleteRequestFailed: false,

    item: null,
    collection: [],
    total: 0,
    size: 0,
    from: 0,
    aggs: null
  };

  const mutations = {
    ...addons.mutations,
    [GET_BY_ID.STARTED](state) {
      state.isGetRequestPending = true;
      state.isGetRequestFailed = false;
    },
    [GET_BY_ID.FAILED](state) {
      state.isGetRequestPending = false;
      state.isGetRequestFailed = true;
      state.item = null;
    },
    [GET_BY_ID.COMPLETED](state, item) {
      state.isGetRequestPending = false;
      state.item = item;
    },

    [GET_COLLECTION.STARTED](state) {
      state.isGetCollectionRequestPending = true;
      state.isGetCollectionRequestFailed = false;
    },
    [GET_COLLECTION.FAILED](state) {
      state.isGetCollectionRequestPending = false;
      state.isGetCollectionRequestFailed = true;
    },
    [GET_COLLECTION.COMPLETED](state, collection) {
      state.isGetCollectionRequestPending = false;

      if (Array.isArray(collection)) {
        state.collection = collection;
      } else {
        state.collection = collection.data;
        state.aggs = collection.aggs;
        state.total = collection.total;
        state.size = collection.size;
        state.from = collection.from;
      }
    },

    [UPDATE.STARTED](state) {
      state.isUpdateRequestPending = true;
      state.isUpdateRequestFailed = false;
    },
    [UPDATE.FAILED](state) {
      state.isUpdateRequestPending = false;
      state.isUpdateRequestFailed = true;
    },
    [UPDATE.COMPLETED](state, item) {
      state.isUpdateRequestPending = false;
      const index = state.collection.findIndex(t => t.id === item.id);
      if (index > -1) {
        state.collection[index] = item;
      }
    },

    [CREATE.STARTED](state) {
      state.isCreateRequestPending = true;
      state.isCreateRequestFailed = false;
    },
    [CREATE.FAILED](state) {
      state.isCreateRequestPending = false;
      state.isCreateRequestFailed = true;
    },
    [CREATE.COMPLETED](state, item) {
      state.isCreateRequestPending = false;
      state.collection.push(item);
    },

    [DELETE.STARTED](state) {
      state.isDeleteRequestPending = true;
      state.isDeleteRequestFailed = false;
    },
    [DELETE.FAILED](state) {
      state.isDeleteRequestPending = false;
      state.isDeleteRequestFailed = true;
    },
    [DELETE.COMPLETED](state, id) {
      state.isDeleteRequestPending = false;
      const index = state.collection.findIndex(t => t.id === id);
      if (index > -1) {
        state.collection.splice(index, 1);
      }
      if (state.item && state.item.id === id) {
        state.item = null;
      }
    }
  };

  const actions = {
    ...addons.actions,
    async getById({ commit }, id) {
      commit(GET_BY_ID.STARTED);
      try {
        const item = await methods.getById(id);

        commit(GET_BY_ID.COMPLETED, item);
      } catch (e) {
        commit(GET_BY_ID.FAILED);
        logger.error(e);
        throw e;
      }
    },
    async getCollection({ commit }, ...args) {
      commit(GET_COLLECTION.STARTED);
      try {
        const collection = await methods.getCollection(...args);

        commit(GET_COLLECTION.COMPLETED, collection);
      } catch (e) {
        commit(GET_COLLECTION.FAILED);
        logger.error(e);
        throw e;
      }
    },
    async update({ commit }, { id, ...args }) {
      commit(UPDATE.STARTED);
      try {
        const item = await methods.update(id, args);
        commit(UPDATE.COMPLETED, item);
      } catch (e) {
        commit(UPDATE.FAILED);
        logger.error(e);
        throw e;
      }
    },
    async create({ commit }, ...args) {
      commit(CREATE.STARTED);
      try {
        const item = await methods.create(...args);
        commit(CREATE.COMPLETED, item);

        return item;
      } catch (e) {
        commit(CREATE.FAILED);
        logger.error(e);
        throw e;
      }
    },

    async delete({ commit }, id) {
      commit(DELETE.STARTED);
      try {
        await methods.delete(id);
        commit(DELETE.COMPLETED, id);
      } catch (e) {
        commit(DELETE.FAILED);
        logger.error(e);
        throw e;
      }
    }
  };

  return {
    state,
    mutations,
    actions,
    modules
  };
};

export const cruduex = factory({});
